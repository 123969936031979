import React from "react";
import { TemplateViewActions } from "./TemplateViewActions";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { Ribbon } from "../common/Ribbon";
import { MdWorkspacePremium } from "react-icons/md";
import colors from "../colors";
import { getTemplatePreviewImage } from "../../shared/Types/TemplatePreviews";
import { RiTimelineView } from "react-icons/ri";
import { SiNewyorktimes } from "react-icons/si";
import SEO from "../common/SEO";
import SEOContent from "../common/SEOContent";

type Props = {
  showOverlay: boolean;
  templateName: TemplateName;
};

export const TemplateView: React.FC<Props> = ({ showOverlay, templateName }) => {
  const imgUrl = getTemplatePreviewImage(templateName);
  
  // SEO-friendly titles based on template
  const getSeoTitle = () => {
    switch(templateName) {
      case TemplateName.TIMELINE:
        return "Timeline Website Design | Create Beautiful Relationship Timelines";
      case TemplateName.ANNIVERSARY:
        return "Anniversary Website Design | Create Stunning Anniversary Pages";
      case TemplateName.THE_LOVE_TIMES:
        return "The Love Times | Newspaper Style Anniversary Website Design";
      default:
        return "Create Beautiful Relationship Websites | Anniversary, Birthday & Valentine's Day";
    }
  };

  // SEO-friendly descriptions based on template
  const getSeoDescription = () => {
    switch(templateName) {
      case TemplateName.TIMELINE:
        return "Design a beautiful timeline website for your relationship. Perfect for anniversaries, birthdays, and special occasions. Create a stunning visual journey of your love story.";
      case TemplateName.ANNIVERSARY:
        return "Create a premium anniversary website design to celebrate your special day. Perfect for couples looking to showcase their relationship journey with a beautiful digital tribute.";
      case TemplateName.THE_LOVE_TIMES:
        return "Design a newspaper-style website for your anniversary. The Love Times template creates a unique and memorable way to celebrate your relationship with a classic newspaper look.";
      default:
        return "Create beautiful websites for anniversaries, birthdays, and Valentine's Day. Design stunning relationship websites for your girlfriend, boyfriend, or partner. Perfect for celebrating love with a personalized digital tribute.";
    }
  };

  return (
    <div className="TemplateView">
      <SEO 
        title={getSeoTitle()}
        description={getSeoDescription()}
        image={imgUrl}
        url={window.location.href}
      />
      <SEOContent />

      {templateName === TemplateName.TIMELINE && (
        <Ribbon color={colors.lightBlue}>
          <span className="Ribbon-Content">
            <RiTimelineView size={20} />
            <b>Timeline</b>
          </span>
        </Ribbon>
      )}

      {templateName === TemplateName.ANNIVERSARY && (
        <Ribbon color={colors.premiumPurple}>
          <span className="Ribbon-Content">
            <MdWorkspacePremium size={25} />
            <b>Anniversary</b>
          </span>
        </Ribbon>
      )}

      {templateName === TemplateName.THE_LOVE_TIMES && (
        <>
          <Ribbon color={colors.black2}>
            <span className="Ribbon-Content">
              <SiNewyorktimes size={20} />
              <b style={{marginLeft: "5px"}}>The Love Times</b>
            </span>
          </Ribbon>
          <div className="New-Ribbon">
            <span className="New-Ribbon-Content">
              <b>New</b>
            </span>
          </div>
        </>
      )}
      <img 
        style={{ boxShadow: showOverlay && "0 0 10px 5px rgba(var(--info-rgb), 0.75)" }} 
        className="TemplateView-Img" 
        src={imgUrl} 
        alt={`${templateName} template preview - Create beautiful relationship websites`}
      />
      {showOverlay && (
        <div className="TemplateView-Overlay">
          <div className="TemplateView-Overlay-Actions">
            <TemplateViewActions alignment="vertical" templateName={templateName} className={`TemplateView-Overlay-Actions-${templateName}`} />
          </div>
        </div>
      )}
      <style>
        {`
        .TemplateView {
            width: 100%;
            height: 100vh;
            max-width: 270px;
            max-height: 440px;
            box-sizing: border-box;
            position: relative;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, #872640, #3a5f85);
        }
        .TemplateView-Img {
            width: 65vw;
            height: 66vh;
            max-width: 270px;
            max-height: 440px;
            object-fit: contain;
            border: 5px solid black;
            border-radius: 10px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            display: flex;
        }
        .TemplateView-Overlay {
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
        .TemplateView-Overlay-Actions {
            width: 100%;
            padding: 0 4px;
        }
        .Ribbon-Content {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 1.5em;
            color: ${colors.white};
        }
        .New-Ribbon {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #e74c3c;
            padding: 5px 10px;
            border-radius: 0 0 10px 0;
            z-index: 10;
        }
        .New-Ribbon-Content {
            display: flex;
            align-items: center;
            font-size: 1em;
            color: ${colors.white};
        }
     `}
      </style>
    </div>
  );
};
