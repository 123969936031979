import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../common/DefaultButton";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import { Footer } from "../Footer/Footer";
import Header from "../header/Header";
import { HomepageTemplates } from "./HomepageTemplates";
import "@fontsource/barlow-semi-condensed";
import { FeatureColumns } from "./FeatureColumns";
import { HappyCustomers } from "./HappyCustomers";
import { SecondFeatureColumns } from "./SecondFeatureColumns";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import AnimatedProfiles from "./AnimatedProfiles";
import SEO from "../common/SEO";
import SEOContent from "../common/SEOContent";

const initialProfiles = [
  "/images/cute-7441224_640.jpg",
  "/images/girl-748932_1280.jpg",
  "/images/girl-6486470_1280.jpg",
  "/images/jazz-1772327_1280.jpg",
  "/images/man-1845814_1280.jpg",
];

const ReviewSection = React.memo(() => {
  const positions = initialProfiles.map(() => Math.random() > 0.5 ? "top" : "bottom");

  return (
    <div className={styles.infoRowSecondColumn}>
      <div className={styles.reviewArea}>
        <div className={styles.reviewFirstRow}>Customer Satisfaction Rate</div>
        <div className={styles.reviewSecondRow}>
          <div className={styles.reviewContainer}>
            <div className={styles.reviewText}>4.8</div>
            <div className={styles.rating}>
              {[0, 0.2, 0.4, 0.6, 0.8].map((delay, index) => (
                <span
                  key={index}
                  className={`${styles.star} ${index === 4 ? styles.half : ""}`}
                  style={{ "--delay": `${delay}s` } as React.CSSProperties}
                />
              ))}
            </div>
            <AnimatedProfiles profiles={initialProfiles} positions={positions} />
          </div>
        </div>
        <div className={styles.reviewThirdRow}>
          <div style={{ opacity: 0 }} className={styles.reviewText}>
            5.0
          </div>
          <div>Based on 152 reviews.</div>
        </div>
      </div>
    </div>
  );
});
ReviewSection.displayName = "ReviewSection";

const HomePage: React.FC = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { pathname } = window.location;

  if (pathname !== "/") {
    navigate("/");
  }

  useEffect(() => {
    if (state.userDto.state === LoadingState.LOADED) {
      navigate("/dashboard");
      return;
    }

    const element = document.querySelector("#selectTemplateText");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          element.classList.add(styles.animateTyping);
        }
      });
    });

    observer.observe(element);
  }, [state.userDto]);

  function handleStartClick(): void {
    navigate("/templates");
  }

  return (
    <div className={styles.homePage}>
      <SEO 
        title="Create Beautiful Relationship Websites | Anniversary, Birthday & Valentine's Day"
        description="Design stunning websites for anniversaries, birthdays, and Valentine's Day. Create personalized digital tributes for your loved one with our easy-to-use templates."
        keywords="anniversary website design, birthday website, website for girlfriend, website for valentines, design a website for anniversary, relationship timeline, love story website, digital anniversary gift, personalized relationship website"
        url={window.location.href}
      />
      <SEOContent />
      
      <Header />
      <div className={styles.headline}>
        {currentLanguage === "en" && (
          <h2>
            Create an <span>I love you</span> website
          </h2>
        )}
        {currentLanguage === "tr" && (
          <h2>
            <span>Seni seviyorum </span> sürprizi hazırla
          </h2>
        )}
        <div className={styles.homePageFirstAnimatedText}>
          <span> {t("young-and-romantic")} </span>
        </div>
        <div className={styles.homePageSecondAnimatedText}>
          <span> {t("no-coding-skills-required")} </span>
        </div>
      </div>
      <div className={styles.firstPhotoContainer}>
        <img
          className={styles.firstPhoto}
          pre-load="true"
          src="https://ik.imagekit.io/ex5o5cwj51/main-image-small.jpeg?updatedAt=1698003807847"
          alt=""
        />
      </div>
      <div className={styles.homePageStartButton}>
        <DefaultButton color="gradient" text={t("get-started")} onClick={handleStartClick} />
        <span>No Credit Card required</span>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoRowFirstColumn}>
          +3,000 Websites <br /> Created
        </div>
        <ReviewSection />
      </div>
      <h2 id="selectTemplateText" className={styles.selectTemplateText}>
        {t("start-with-a-template")}
      </h2>
      <div className={styles.demoViewContainer}>
        <HomepageTemplates />
      </div>
      <div className={styles.homePageInfoArea}>
        <div className={styles.secondFeaturesHeadline}>
          <h3>{t("why-lovepage-io")}</h3>
          <p>{t("why-lovepage-io-sub")}</p>
        </div>

        <FeatureColumns />

        <HappyCustomers />

        <div className={styles.secondFeaturesHeadline}>
          <h3>{t("special-way-to-express-your-love")}</h3>
        </div>

        <SecondFeatureColumns />

        <div className={styles.homepageVideoTutorial}>
          <h3>
            {t("watch-our-video-tutorial")}
            <br />
            {t("create-in-2-mins")}
          </h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/6tmIi0mF3Ys"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay;
             clipboard-write; encrypted-media;
              gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <p className={styles.tryOutText}>
          {t("there-is-no-better-way")}
          &nbsp;<h3>{t("try-it-now")}</h3>
        </p>
        <div className={styles.homePageStartButton}>
          <DefaultButton color="gradient" text={t("get-started")} onClick={handleStartClick} />
          <span>No Credit Card required</span>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
