import { css } from "@emotion/css";
import React from "react";
import { TemplateView } from "../create/TemplateView";
import { TemplateName } from "../../shared/Enums/TemplateName";
import { useTranslation } from "react-i18next";

export const HomepageTemplates = () => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const { t } = useTranslation();

  function handleSelect(index: number) {
    if (selectedItem === index) {
      setSelectedItem(null);
    } else {
      setSelectedItem(index);
    }
  }

  const style = css`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    padding: 0.5em 0 0.5em 0.5em;
    gap: 1em;
    box-sizing: border-box;
    @media (min-width: 550px) {
      width: 100%;
    }
  `;
  const templateItemStyle = css`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:last-child {
      padding-right: 0.5em;
    }
  `;
  const headerStyle = css`
    margin-bottom: 8px;
    font-size: 1.2em;
    font-style: italic;
  `;

  return (
    <div className={style}>
      <div onClick={() => handleSelect(0)} className={templateItemStyle}>
        <h4 className={headerStyle}>{t("the-love-times")}</h4>
        <TemplateView showOverlay={selectedItem === 0} templateName={TemplateName.THE_LOVE_TIMES} />
      </div>
      <div onClick={() => handleSelect(1)} className={templateItemStyle}>
        <h4 className={headerStyle}>{t("anniversary")}</h4>
        <TemplateView showOverlay={selectedItem === 1} templateName={TemplateName.ANNIVERSARY} />
      </div>
      <div onClick={() => handleSelect(2)} className={templateItemStyle}>
        <h4 className={headerStyle}>{t("timeline-romance")}</h4>
        <TemplateView showOverlay={selectedItem === 2} templateName={TemplateName.TIMELINE} />
      </div>
    </div>
  );
};
